import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/77c62ea090dcfe6d3ccb46fdabd0f7e8/2bef9/Morne_ancient_manuscript_drawing_of_a_human_ascending_from_the__bf546970-643b-4535-b1d1-cabcbed11d1c.png",
            "srcSet": ["/static/77c62ea090dcfe6d3ccb46fdabd0f7e8/5ff7e/Morne_ancient_manuscript_drawing_of_a_human_ascending_from_the__bf546970-643b-4535-b1d1-cabcbed11d1c.png 375w", "/static/77c62ea090dcfe6d3ccb46fdabd0f7e8/1d69c/Morne_ancient_manuscript_drawing_of_a_human_ascending_from_the__bf546970-643b-4535-b1d1-cabcbed11d1c.png 750w", "/static/77c62ea090dcfe6d3ccb46fdabd0f7e8/2bef9/Morne_ancient_manuscript_drawing_of_a_human_ascending_from_the__bf546970-643b-4535-b1d1-cabcbed11d1c.png 1024w"],
            "width": "250px",
            "className": "right center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Manuscript depicting the prophesized Ascension`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`The Ascension is a foretold event in which the `}<a parentName="p" {...{
        "href": "/Deep%20Dweller",
        "title": "Deep Dweller"
      }}>{`Msanti`}</a>{` are able to rise back to the surface and are forgiven by the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Water",
        "title": "Primal Spirit of Water"
      }}>{`Primal Water Spirit`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      